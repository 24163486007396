<template>
  <div>
    <div class="pc container">
      <div class="h5 main" style="padding:40px 0 28px 0">Solution API 신청 내역</div>

      <div class="panel-container">
        <table>
          <tr>
            <th>신청날짜</th>
            <th>신청자명</th>
            <th>주문전환 방식</th>
            <th>상태</th>
          </tr>
          <tr class="menu-item unselect" v-for="(item,idx) in list" :key="'menu-'+idx" @click="clickItem(item)">
            <td width="140">{{ datesFormat(item.created_time, 'date_3') }}</td>
            <td width="200">{{ item.name }}</td>
            <td width="610" style="padding-right: 12px"><span class="ellipsis-1">{{ item.api_request.packages.toString() }}</span></td>

            <td width="120" class="flex"><div :class="`status status-${item.status}`">{{ contentStatus(item) }}</div></td>
          </tr>
        </table>
        <lp-paginate :filtered="itemFiltered"
                     @getData="getData"
                     class="padding-top-80"
                     v-show="list.length>0"></lp-paginate>

        <div v-if="list && list.length === 0" class="empty-view" style="padding-top:40px">신청내역이 없습니다</div>
      </div>
    </div>

    <div class="mobile container">
      <div class="h7 main" style="padding:24px 0 16px 0">Solution API 신청 내역</div>
      <div class="panel-container" v-for="(item,idx) in list" :key="'menu-'+idx" @click="clickItem(item)">
        <div class="subtitle7 main ellipsis-1">{{ item.api_request.packages.toString() }}</div>
        <div class="flex-align" style="margin-top:12px">
          <div :class="`status status-${item.status}`">{{ contentStatus(item) }}</div>
          <div class="body6 sub3" style="margin-left:8px">{{ datesFormat(item.created_time, 'date_3') }}</div>
        </div>
      </div>
      <lp-paginate :filtered="itemFiltered"
                   @getData="getData"
                   class="padding-top-40"
                   v-show="list.length>0"></lp-paginate>

      <div v-if="list && list.length === 0" class="empty-view">신청내역이 없습니다</div>
    </div>
  </div>

</template>

<script>
  import LpPaginate from "../component/LpPaginate";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  export default {
    name: "SolutionApiList",
    components: {LpPaginate},
    mixins:[
      UserAPIMixin
    ],
    data() {
      return {
        list: [],
        itemFiltered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 10
        }
      }
    },
    created() {
      this.getData();
    },

    methods:{
      getData(){
        this.request.user.get('launchpack/service/inquiry?category=1', {
          params: this.itemFiltered
        }).then(res => {
            this.list = res.data.data;
            this.itemFiltered.page_length = res.data.page_length;
            this.itemFiltered.cur_page = res.data.cur_page;
            this.itemFiltered.total_page = res.data.total_page;
            this.itemFiltered.count = res.data.count;

          });

      },
      clickItem(item) {
        this.routerPush(`/solution_api_detail?id=${item.id}`);
      },
      contentStatus(menu) {
        let status = {
          0: '신청 미확인',
          1: '신청 확인',
          2: '상담완료'
        };
        /*if (menu.status===0) value = '신청완료';
        else if(menu.status===2 && menu.service_status===1) value = '상담 후 솔루션 이용 결정';
        else if(menu.status===2 && menu.service_status===2) value = '상담 후 솔루션 이용 포기';
        else if (menu.status===1) value = '상담 대기중';
        else if (menu.status===2) value = '상담 완료';*/
        return status[menu.status];
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import'~assets/css/lp_main'
  table
    width 100%
  th
    padding-bottom 20px
  td
    padding 20px 0
    border-bottom 1px solid $gray1
  th
    border-bottom 1px solid $sub5

  .menu-header
    padding-bottom 20px
    border 1px solid $sub5
    display flex
    justify-content space-between

  .panel-container
    margin-bottom 8px
</style>
